<template>
  <!-- MODAL EXPORT PLAYLIST -->
  <b-modal
    id="export-playlist"
    ref="export-playlist"
    :title="$t('table.export')"
    :ok-disabled="!selectedType"
    :ok-title="exporting ? $t('table.exporting') + '...' : $t('table.export')"
    :cancel-title="$t('table.cancel')"
    :busy="exporting"
    @show="selectedDate = date"
    @ok.prevent="exportReport"
    @hidden="clearFields"
  >
    <b-form-group :label="$t('table.date')" label-for="playlist-datepicker">
      <datepicker-wrapper id="playlist-datepicker" v-model="selectedDate" :disabled="exporting" required />
    </b-form-group>
    <b-form-group :label="$t('table.selectExportType')">
      <MultiSelect
        v-model.trim="selectedType"
        label="name"
        track-by="id"
        :options="options"
        :placeholder="$t('table.selectExportType')"
        :disabled="exporting"
      />
    </b-form-group>
    <template v-if="selectedType">
      <b-form-group v-if="selectedType.id === 'b'" :label="$t('table.timeSlot')">
        <select v-model="selectedTimeSlot" class="custom-select" :disabled="exporting">
          <option class="option" value="0.5">0.5</option>
          <option class="option" value="1">1</option>
          <option class="option" value="3">3</option>
          <option class="option" value="6">6</option>
          <option class="option" value="24">24</option>
        </select>
      </b-form-group>
      <b-form-checkbox
        v-if="selectedType.id === 'b' || selectedType.id === 'c'"
        id="checkbox-with-id"
        v-model="withCommercialID"
        name="checkbox-with-id"
        :disabled="exporting"
      >
        {{ $t('table.withCommercialID') }}
      </b-form-checkbox>
      <b-form-checkbox
        v-if="selectedType.id === 'b'"
        id="checkbox-time-format"
        v-model="use_mounting_list_time_format"
        name="checkbox-time-format"
        :disabled="exporting"
      >
        {{ $t('table.duration') }} H:mm:ss
      </b-form-checkbox>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import downloadFileHandler from '@/mixins/downloadFileHandler';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';

export default {
  name: 'ModalExportPlaylist',
  components: { MultiSelect, DatepickerWrapper },
  mixins: [downloadFileHandler],
  props: {
    channel: { type: [String, Object], default: undefined },
    agency: { type: [String, Object], default: undefined },
    date: { type: [String, Object], default: undefined },
    advertiser: { type: [String, Object], default: undefined },
    brand: { type: [String, Object], default: undefined },
    placement_type: { type: [String, Object], default: undefined },
    commercial_type: { type: [String, Object], default: undefined },
    measurement_company: { type: [String, Object], default: undefined },
    target_audience: { type: [String, Object], default: undefined },
  },
  data() {
    return {
      selectedType: '',
      withCommercialID: false,
      selectedTimeSlot: 6,
      exporting: false,
      use_mounting_list_time_format: false,
      selectedDate: this.date,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),
    options() {
      const optionsArray = [
        { id: 'a', name: this.$i18n.t('table.exportTypeA') }, // Radio Time+ID
        { id: 'b', name: this.$i18n.t('table.exportTypeB') }, // TV/Radio network
        //{ id: 'b2', name: this.$i18n.t('table.exportTypeB2') }, // TV/Radio network with commercial ID. DEPRECATED
        { id: 'c', name: this.$i18n.t('table.exportTypeC') }, // TV list for M.C.
      ];
      if (!process.env.VUE_APP_COUNTRY_CODE || process.env.VUE_APP_COUNTRY_CODE === 'UZ') {
        optionsArray.push(
          { id: 'uzb_a', name: this.$i18n.t('table.playlist') + ' 1' },
          { id: 'uzb_b', name: this.$i18n.t('table.playlist') + ' 2' },
          { id: 'uzb_c', name: this.$i18n.t('table.playlist') + ' 3' }
        );
      }
      return optionsArray;
    },
  },
  methods: {
    clearFields() {
      this.selectedType = '';
      this.selectedTimeSlot = 6;
      this.exporting = false;
      this.withCommercialID = false;
      this.use_mounting_list_time_format = false;
      this.selectedDate = this.date || null;
    },

    hideModalFixSpots() {
      this.$bvModal.hide('export-playlist');
    },

    async exportReport() {
      this.exporting = true;
      if (this.selectedType?.id.includes('uzb_')) {
        await this.$store.dispatch('GET_EXPORT_BOOKING_ONE_DAY', {
          id: this.channel.id,
          type: this.selectedType.id,
          params: {
            format: 'xlsx',
            date: this.selectedDate,
          },
          handler: (res) => {
            this.prepareAndDownloadFile(res);
            this.hideModalFixSpots();
          },
        });
      } else {
        await this.$store.dispatch('GET_EXPORT_BOOKING_ONE_DAY', {
          id: this.channel.id,
          type: this.selectedType.id,
          params: {
            format: this.selectedType.id === 'c' ? 'xls' : 'xlsx',
            date: this.selectedDate,
            agency_id: this.agency ? this.agency.id : null,
            advertiser_id: this.advertiser ? this.advertiser.id : null,
            brand_id: this.brand ? this.brand.id : null,
            placement_type_id: this.placement_type ? this.placement_type.id : null,
            commercial_type_id: this.commercial_type ? this.commercial_type.id : null,
            measurement_company_id: this.measurement_company ? this.measurement_company.id : null,
            target_audience_id: this.target_audience ? this.target_audience.id : null,
            time_period: this.selectedType.id === 'b' ? +this.selectedTimeSlot * 60 * 60 : undefined,
            prefix_commercial_name_with_id: (this.selectedType.id === 'b' || this.selectedType.id === 'c') && this.withCommercialID ? 1 : 0,
            use_mounting_list_time_format: this.selectedType.id === 'b' && this.use_mounting_list_time_format ? 1 : 0,
          },
          handler: (res) => {
            this.prepareAndDownloadFile(res);
            this.hideModalFixSpots();
          },
        });
      }
      this.exporting = false;
    },
  },
};
</script>

<style lang="sass" scoped></style>
